import * as React from "react";
import { graphql } from "gatsby";
import { Box, Text, Flex } from "@chakra-ui/react";
import SeoComponent from "../components/SeoComponent";

const isBrowser = typeof window !== "undefined";

function FindPage(props) {
  const { data } = props;
  const mapRef = React.useRef(null);
  const seoImg = data.sanityPage.seoImage?.image;

  React.useEffect(() => {
    // console.log('mapRef: ', mapRef);
  }, [mapRef]);

  return (
    <>
      <SeoComponent
        title={data.sanityPage.seoTitle}
        description={data.sanityPage.seoDescription}
        imageUrl={seoImg?.asset?.url}
      />

      <Box w="100%" minH={{ base: "600px", md: "600px" }} position="relative">
        {/* SUBTITLE */}
        <Text
          w={{ base: "70vw" }}
          color="primary"
          fontSize={{ base: "12px", md: "15px" }}
          fontWeight={{ base: "600", md: "600" }}
          letterSpacing={{ base: "0px", md: "0px" }}
          lineHeight={{ base: "24px", md: "30px" }}
          textAlign="center"
          mb={{ base: "12px", md: "19px" }}
          width="80%"
          position="relative"
          zIndex={99}
          margin="auto"
          paddingTop="100px"
        >
          Enter your zip code below to find nearby dispensaries that carry Wana.
          While we do our best to keep menus up to date, it's always best to
          call your preferred dispensary and confirm they have your desired
          product in stock. Prices may vary by store.
        </Text>
        {/* GRAY SVG */}
        <Box
          w="100%"
          h={{ base: "600px", md: "600px" }}
          position="absolute"
          top="0"
          left="0"
          style={{ zIndex: 0 }}
        >
          <svg
            viewBox="0 0 500 149"
            preserveAspectRatio="none"
            style={{ width: "100%", height: "100%", zIndex: 0 }}
          >
            <path
              fill="#F1F0FA"
              fillOpacity="1"
              d="M-3.10,110.05 C218.68,112.02 411.68,186.03 502.54,104.13 L500.00,0.00 L0.00,0.00 Z"
            ></path>
          </svg>
        </Box>

        {/* MAP WIDGET */}
        <MapStore
          refTouse={mapRef}
          accountID={data.allSanityStoreRocketAccountId.nodes[0].accountID}
        />
      </Box>
    </>
  );
}

const MapStore = ({ refTouse, accountID }) => {
  let config = {
    selector: ".store-locator-widget",
    account: accountID,
  };

  React.useEffect(() => {
    if (isBrowser) {
      // console.log('MapStore: ', window.StoreRocket);
      if (window.StoreRocket) {
        window.StoreRocket.init(config);
      }
    }
  }, []);

  return (
    <Box
      ref={refTouse}
      className="store-locator-widget"
      w={{ base: "90vw", xl: "1387px" }}
      pt={{ base: "79px", md: "79px" }}
      mx="auto"
    ></Box>
  );
};

export const query = graphql`
  query {
    sanityPage(slug: { current: { eq: "find" } }) {
      _id
      slug {
        current
      }
      seoTitle
      seoDescription
      seoImage {
        image {
          asset {
            url
          }
        }
      }
    }
    allSanityStoreRocketAccountId {
      nodes {
        accountID
      }
    }
  }
`;

export default FindPage;
